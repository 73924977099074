// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cancellation-and-claims-process-js": () => import("./../../../src/pages/cancellation-and-claims-process.js" /* webpackChunkName: "component---src-pages-cancellation-and-claims-process-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-complaints-procedure-js": () => import("./../../../src/pages/complaints-procedure.js" /* webpackChunkName: "component---src-pages-complaints-procedure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-init-basket-js": () => import("./../../../src/pages/init-basket.js" /* webpackChunkName: "component---src-pages-init-basket-js" */),
  "component---src-pages-privacy-cookie-policy-js": () => import("./../../../src/pages/privacy-cookie-policy.js" /* webpackChunkName: "component---src-pages-privacy-cookie-policy-js" */),
  "component---src-pages-tree-planted-js": () => import("./../../../src/pages/tree-planted.js" /* webpackChunkName: "component---src-pages-tree-planted-js" */),
  "component---src-pages-vehicle-checkout-v-2-js": () => import("./../../../src/pages/vehicle-checkout-v2.js" /* webpackChunkName: "component---src-pages-vehicle-checkout-v-2-js" */),
  "component---src-pages-wedding-checkout-js": () => import("./../../../src/pages/wedding-checkout.js" /* webpackChunkName: "component---src-pages-wedding-checkout-js" */),
  "component---src-pages-wedding-insurance-js": () => import("./../../../src/pages/wedding-insurance.js" /* webpackChunkName: "component---src-pages-wedding-insurance-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/products-page.js" /* webpackChunkName: "component---src-templates-products-page-js" */)
}

